.permission-table-header {
  background-color: var(--#{$prefix}light-purple) !important;
}
// .bg-light-pink ,  .bg-primary-subtle {
//   background-color: $pink-extralight !important;
// }

.svg-icon {
  &.undo-redo-btn {
    color: #2d2d4a;

    &.disabled {
      color: #b3c1ce !important;
    }
  }
}

.modal-content {
  border-radius: 0;
}

.custom-shadow {
  box-shadow: var(--#{$prefix}custom-box-shadow);
}

.custom-img-shadow {
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.125);
}

.user-icon {
  // background-color: $secondary-light;
  border-radius: 50%;
  color: $white;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  flex-shrink: 0;
  border: 1px solid $secondary-light;
}

.user-icon_template {
  background-color: #ffffff;
  border-radius: 50%;
  color: #000000;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  flex-shrink: 0;
}

.symbol-60px {
  height: 60px;
  width: 60px;
}

.card-tab-table-shadow {
  box-shadow: 0px 16px 16px #dadee8;
}

.cdk-drop-list-dragging {
  cursor: grabbing;
}

.media-table-select {
  .ng-select-container {
    border-radius: 0.625rem !important;
    border: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color) !important;
  }
}

// .h-menu-cat-row {
//     height: calc(100vh - 160px);
// }

// notification modal

.notification-unread::after {
  content: "";
  background-color: red;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  left: 10px;
}

// cdk-drag-and-drop-custom-style
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag {
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

.before-fw-bold::before {
  font-weight: bolder !important;
}

.before-dark::before {
  color: #2d2d4a !important;
}

.btn-bg-light-purple:hover {
  color: #2d2d4a !important;
}

.btn-bg-light-purple {
  background-color: var(--#{$prefix}light-purple);

  &.purple-btn {
    background-color: var(--jg-purple-btn);
  }
}

.btn-bg-light-purple:not(.purple-btn):hover {
  background-color: var(--#{$prefix}light-purple) !important;
}

.btn-bg-light-purple:not(.btn-bg-light-purple:not(.btn-primary):hover):hover {
  background-color: var(--#{$prefix}light-purple) !important;
}

.xs-switch {
  height: 23px !important;
  width: 41px !important;

  &[type="checkbox"] {
    background-size: 15px !important;
    font-weight: bold !important;
  }
}

.template-content {
  &.app-content {
    @media (max-width: 991.98px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.ql-font-mirza {
  font-family: "Mirza" !important;
}

.ql-font-merriweather {
  font-family: " Merriweather,serif" !important;
}

.ql-font-roboto {
  font-family: "Roboto" !important;
}

.ql-font-playfair {
  font-family: "Playfair Display", serif;
}

.ql-font-hind-madurai {
  font-family: "Hind Madurai", sans-serif;
}

.ql-font-inconsolota {
  font-family: "Inconsolata" !important;
}

.ql-font-arial {
  font-family: "Arial" !important;
}

.ql-picker.ql-weight {
  width: 98px;
}

.ql-picker.ql-weight .ql-picker-label::before,
.ql-picker.ql-weight .ql-picker-item::before {
  content: "Normal";
}

.ql-picker.ql-weight .ql-picker-label[data-value="100"]::before,
.ql-picker.ql-weight .ql-picker-item[data-value="100"]::before {
  content: "Lighter";
}

.ql-picker.ql-weight .ql-picker-label[data-value="400"]::before,
.ql-picker.ql-weight .ql-picker-item[data-value="400"]::before {
  content: "Normal";
}

.ql-picker.ql-weight .ql-picker-label[data-value="500"]::before,
.ql-picker.ql-weight .ql-picker-item[data-value="500"]::before {
  content: "Medium";
}

.ql-picker.ql-weight .ql-picker-label[data-value="700"]::before,
.ql-picker.ql-weight .ql-picker-item[data-value="700"]::before {
  content: "Bold";
}

.ql-picker.ql-weight .ql-picker-label[data-value="900"]::before,
.ql-picker.ql-weight .ql-picker-item[data-value="900"]::before {
  content: "Bolder";
}

// blockquote:first-of-type:before {
//     content: open-quote;
//     display: inline-block;
//     color: orange;
//     // font-size: 4em;
//     position: relative;
// }

// blockquote:last-of-type:after {
//     content: close-quote;
//     display: inline-block;
//     color: orange;
//     // font-size: 4em;
//     position: relative;
// }

.ql-snow .ql-editor blockquote:not(:first-of-type),
.ql-snow .ql-editor blockquote:not(:last-of-type) {
  margin-top: 0px;
  margin-bottom: 0px;
}

.rotate-c180 {
  transform: rotate(180deg);
}

.form-check-drawer {
  padding-left: 2.5remF;
}

.form-control,
.ng-select-container {
  font-weight: 500 !important;
}

// .upload-file {
//   background-color: var(--#{$prefix}file-upload-bg) !important;
//   border-color: var(--#{$prefix}file-upload-text-color) !important;
// }

.filter-modal-label-bg .form-control {
  background-color: var(--jg-light-purple) !important;
}

.form-control,
.ng-select-container {
  font-weight: 500 !important;
}

// .upload-file {
//   background-color: var(--#{$prefix}file-upload-bg) !important;
//   border-color: var(--#{$prefix}file-upload-text-color) !important;
// }

.filter-modal-label-bg .form-control {
  background-color: var(--jg-light-purple) !important;
}

// table-layout-one-header style
.btn-active-purple:active {
  background-color: var(--#{$prefix}purple-btn);
  color: var(--#{$prefix}delet-btn-color);
}

.btn-active-light-purple:hover {
  background-color: var(--#{$prefix}light-purple) !important;
  // color: var(--#{$prefix}black-color) !important;
}

.btn-active-purple:hover {
  background-color: var(--#{$prefix}purple-btn) !important;
  color: var(--#{$prefix}number-text-color) !important;
}

.purple-btn .svg-icon {
  color: #fff;
}

.solid-cntrol {
  .custom-ng-select.ng-select-sm {
    .ng-select {
      .ng-select-container {
        padding: 0.82rem 0.75rem !important;
        border: 2px solid #e1e3ea;
      }
    }
  }
}

.table-filter-content {
  .form-control {
    padding: 0.7rem 0.75rem !important;
    border: 2px solid #e1e3ea;
    border-radius: 3px;
  }

  .custom-ng-select.ng-select-sm .ng-select .ng-select-container {
    padding: 0.82rem 0.75rem;
    border: 2px solid #e1e3ea;
  }
}

.media-list,
.feed-list {
  .dropdown-toggle {
    @media (max-width: 1200px) {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }
}

.media-list.article-list {
  img {
    object-fit: cover !important;
  }
}

// .my-task-list {
//     .dropdown-toggle {
//         @media (max-width: 1200px) {
//             padding-top: 5px !important;
//             padding-bottom: 5px !important;
//         }
//     }
// }

.filter-date .form-control {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  padding-left: 2rem !important;
}

.media-checked {
  top: 8px;
  left: 8px;

  .form-check-input[type="checkbox"] {
    border-radius: 0.3rem;
  }
}

.media-view-editor {
  right: 8px;
  top: 8px;
}

.bg-file-upload,
.bg-file-upload-border {
  border: 2px dashed var(--#{$prefix}file-upload-text-color);
}

.remove-image {
  top: 8px;
  right: 8px;
  // z-index: 1;
}

.download-image {
  top: 8px;
  left: 8px;
  // z-index: 2;
}

.filter-date .form-control {
  @media (max-width: 1200px) {
    padding-top: 0.65rem !important;
    padding-bottom: 0.65rem !important;
    padding-left: 2.5rem !important;
  }
}

.filter-date .form-control {
  @media (max-width: 768px) {
    padding-top: 0.65rem !important;
    padding-bottom: 0.65rem !important;
    padding-left: 2.5rem !important;
  }
}

.ql-color .ql-picker-options [data-value="custom-color"]:before {
  content: "Pick Color";
}

.ql-color .ql-picker-options [data-value="custom-color"] {
  background: none !important;
  width: 100% !important;
  height: 25px !important;
  text-align: center;
  color: blue;
  text-decoration: underline;
}

.ql-background .ql-picker-options [data-value="custom-background-color"]:before {
  content: "Pick Color";
}

.ql-background .ql-picker-options [data-value="custom-background-color"] {
  background: none !important;
  width: 100% !important;
  height: 25px !important;
  text-align: center;
  color: blue;
  text-decoration: underline;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
  background: none;
  color: #585858;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight {
  background: var(--#{$prefix}stripped-color);
  color: #585858;
}

.add-img-sm-btn {
  button {
    font-size: 11px !important;
    padding: 5px 8px !important;
  }

  .dropdown-toggle::after {
    margin-left: 0.5em;
    font-size: 14px;
  }
}

.editor-user-dropdown {
  .menu-link:hover:not(.disabled):not(.active):not(.here) {
    color: var(--jg-primary) !important;
  }
}

meter::-webkit-meter-optimum-value {
  background-color: var(--jg-purple-btn);
}

meter::-moz-meter-bar {
  background-color: var(--jg-purple-btn);
}

meter {
  height: 40px;
}

.meter-container {
  position: relative;
  margin-bottom: 5px;
}

.meter-progress {
  font-weight: 500;
  color: #cacaca;
  z-index: 9;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-date .form-control {
  @media (max-width: 1200px) {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
    padding-left: 3rem !important;
    padding-right: 35px !important;
  }
}

.filter-date .form-control {
  @media (max-width: 768px) {
    padding-top: 0.75rem !important;
    padding-bottom: 0.95rem !important;
    padding-left: 3rem !important;
    padding-right: 35px !important;
  }
}

.form-control:focus {
  color: var(--#{$prefix}black-color);
}

.sub-cat-table .p-datatable-scrollable .p-datatable-tbody>tr>td:first-child,
.cat-table .p-datatable-scrollable .p-datatable-tbody>tr>td:first-child,
.sub-cat-table .p-datatable-scrollable th:first-child,
.cat-table .p-datatable-scrollable th:first-child {
  flex: 0 0 auto;
}

.sub-cat-table .p-datatable-scrollable .p-datatable-tbody>tr>td:last-child:not(:has(.no-result)),
.cat-table .p-datatable-scrollable .p-datatable-tbody>tr>td:last-child:not(:has(.no-result)),
.sub-cat-table .p-datatable-scrollable th:last-child:not(:has(.no-result)),
.cat-table .p-datatable-scrollable th:last-child:not(:has(.no-result)) {
  max-width: 150px;
  min-width: 150px;
  justify-content: center;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
}

.drawer-wrapper {
  z-index: 99;
}

.e-prvw-breadcrumb-item:last-child {
  span {
    display: none;
  }
}

.bredcrumb-item {
  background-color: var(--jg-light-purple);
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
}

.splide__track.splide__track--slide {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

// .splide-custom_icons {
//     .splide__arrow {
//         svg {
//             path {
//                 stroke: #8d97ad;
//                 stroke-width: 2;
//                 stroke-linecap: round;
//             }
//         }
//     }
// }
.notification-box {
  top: 15px !important;
}

.collection-striped:nth-child(even) {
  background-color: var(--#{$prefix}stripped-color) !important;
}

.collec-list {
  .ng-select-container {
    width: 200px !important;
    padding: 0.475rem 1rem !important;
    line-height: 1.5 !important;

    input {
      width: 120px !important;
      text-overflow: ellipsis !important;
      display: inline-block !important;
    }
  }
}

@media (max-width: 768px) {
  .collec-list {
    .ng-select-container {
      width: 200px !important;
      padding: 0.22rem 1rem !important;
      line-height: 1.4 !important;
    }
  }

  .mobile-drawer-inner-scroll {
    max-height: 600px !important;
    overflow-y: auto !important;
  }
}

.notification-box {
  top: 15px !important;
}

.notification-box {
  top: 15px !important;
}

// .article-image {
//     img {
//         width: 50px !important;
//         height: 50px !important;
//     }
// }

.d-img {
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin: 0.1rem 0.4rem 0.1rem 0;
    padding: 0rem 0.4rem;
  }
}

.strength {
  background-color: var(--#{$prefix}green-color) !important;
}

.collection-striped:nth-child(even) {
  background-color: var(--#{$prefix}stripped-color);
}

.relative-pos {
  position: relative;
}

.outline-hidden {
  outline: none;
}

.margin-b-0 {
  margin-bottom: 0;
}

.m-0-auto {
  margin: 0 auto;
}

.c-pointer {
  cursor: pointer !important;
}

.c-default {
  cursor: default !important;
}

.text-18px {
  font-size: 18px;
}

.border-b-2px {
  border-bottom: 2px solid #e0e6f5;
}

.border-embed-box-2px {
  border: 2px solid #e0e6f5;
}

.border-embed-box-2px {
  &.active {
    background-color: var(--jg-gray-200);
  }
}

.jg-white-b-border {
  border-bottom: 1px solid white;
}

.min-height-0 {
  min-height: 0;
}

.jg-quote-padding {
  padding: 10px 20px 0;
}

.vertical-align-sub {
  vertical-align: sub;
}

.notification-dot {
  height: 5px;
  border-radius: 50%;
  width: 5px;
  background-color: red;
}

.file-upload-preview {
  height: fit-content;
  background-color: rgb(243, 245, 255);
  width: 200px;
  height: 250px;
  border-radius: 10px;
}

.h-300 {
  height: 300px !important;
}

.comment-view {
  .p-checkbox .p-checkbox-box {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
    height: 1rem;
    border-radius: 0;
    border: none;
  }
}

.jx-controller {
  background: none repeat scroll 0 0 #bd9e53 !important;
}

.jx-arrow.jx-left {
  color: black;
}

.jx-control {
  background-color: black !important;
}

.jx-arrow.jx-right {
  color: black;
}

.media-list {
  p-table {
    height: 100%;
  }
}

.top-left-ribbon {
  transform: rotate(312deg);
  top: 6px;
  left: -20px;
  text-align: center;
}

.top-left-ribbon.feed {
  top: 7px;
  left: -24px;
}

.media-list {
  .form-check.form-check-solid .form-check-input:not(:checked) {
    background-color: var(--#{$prefix}bg-transparent);
    border: 1px solid var(--#{$prefix}input-border);
  }
}

// media sidebar

.rotate-custom-180 {
  transition: transform 0.4s ease-in-out;
}

.rotate-custom-180 {
  transform: rotate(-180deg);
}

.w-transition {
  transition: width 0.4s ease-in-out;
}

.transform-transition {
  transition: transform 0.4s ease-in-out;
}

.folder-sidebar-transition {
  transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.media-sidebar-w {
  width: calc(100% - 216px);
  overflow: auto;
}

.w-0 {
  width: 0 !important;
}

.folder-sidebar-h {
  height: calc(100vh - 175px);
}

.media-gridview-h {
  height: calc(100vh - 310px);
}

.media-list {
  .p-datatable-wrapper {
    height: calc(100vh - 500px);

    @media (max-height: 576px) {
      height: calc(100vh - 300px);
    }
  }
}

.jg-pt-10px {
  padding-top: 10px;
}

.embed-supported {
  &.active {
    background-color: var(--#{$prefix}gray-300);
  }
}

.top-48px {
  top: 48px;
}

.article-list {
  display: block;
}

.media-tableview-h,
.topic-list,
.Media-list,
.article-list,
.reviewer-list {
  .p-datatable {
    .p-datatable-tbody {
      >tr {
        >td {
          padding: 0.5rem 1rem;

          .symbol-30px {
            height: 40px;
            width: 40px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover !important;
            }
          }
        }
      }
    }
  }
}

.media-tableview-h.with-covered-images {
  .p-datatable {
    .p-datatable-tbody {
      >tr {
        >td {
          .symbol-30px {
            img {
              background-color: var(--#{$prefix}gray-300);
              object-fit: contain !important;
            }
          }
        }
      }
    }
  }
}

.menu-item {
  .menu-link {
    .menu-title {
      font-size: 11px;
      line-height: 1;
    }
  }
}

.collec-list {
  p-element .p-datatable-tbody {
    height: 250px;
    overflow-y: auto;
  }
}

// edit global config
.h1-heading-modal {
  font-size: 28px !important;
}

.h2-heading-modal {
  font-size: 23px !important;
}

.h3-heading-modal {
  font-size: 19px !important;
}

.h4-heading-modal {
  font-size: 16px !important;
}

.h5-heading-modal {
  font-size: 14px !important;
}

.h6-heading-modal {
  font-size: 12px !important;
}

.d-1536-flex {
  @media (min-width: 1536px) {
    display: flex !important;
  }
}

.media-table-select {
  .ng-has-value {
    .ng-value-container {
      @media (max-width: 767px) {
        font-size: 0.75rem !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .article-flex-wrap {
    flex-wrap: wrap-reverse;
  }
}

.d-1536-none {
  @media (min-width: 1536px) {
    display: none !important;
  }
}

.global-config-bg {
  background-color: var(--#{$prefix}modal-tab-bg);
}

@media (max-width: 992px) {
  .create-template-sm {
    .form-check.form-check-sm .form-check-input {
      height: 1.45rem;
      width: 1.45rem;
    }
  }
}

.media-library-custom-style {
  app-date-picker {
    input {
      padding: 0.4rem 0 0.4rem 2.5rem !important;
      font-size: 12px !important;
    }
  }

  .input-group-sm>.form-control,
  .input-group-sm>.form-select,
  .input-group-sm>.input-group-text,
  .input-group-sm>.btn {
    padding: 0.4rem 0.75rem !important;
  }

  .form-control-sm {
    min-height: 0 !important;
  }

  .custom-ng-select.ng-select-sm .ng-select .ng-select-container {
    min-height: 0 !important;
    padding: 0.4rem 0.75rem !important;
  }
}

.accordion-custom-style {
  &.comment {
    .common-custom-accordion {
      .accordion-button {
        border-radius: 3px !important;
      }
    }
  }
}

.accordion-custom-style {
  .common-custom-accordion .accordion-button {
    font-size: 11px;
    padding: 9px;
  }

  .common-custom-accordion .accordion-button::after {
    background-size: 10px;
    background-position: center;
  }

  .comment-accordion-border-bottom {
    border-bottom: 1px solid #f4f4f4 !important;
  }

  .comment-accordion-border-bottom:last-child {
    border-bottom: none !important;
  }
}

.comment-padding {
  .accordion-body {
    padding-right: 0;
    padding-left: 0;
  }
}

.textarea-focus-none:focus {
  outline: none;
  border: none;
}

.left-label {
  left: 90%;
}

.article-footer-style {
  display: none;
}

.article-header-btn {
  display: block;
}

.article-name-mobile-view {
  display: none;
}

.article-pos {
  position: relative !important;
  right: 0 !important;
}

.a-editor-gap {
  gap: 15px !important;
}

.article-header-rounded {
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem !important;
}

.article-header-rounded-bottom {
  border-radius: 1rem 1rem 1rem 1rem !important;
}

.article-px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.article-d-height {
  height: 100%;
}

.article-drawer-height {
  height: 100%;
}

.smart-head-pos {
  position: absolute;
  top: -27px;
}

.h-r-preview {
  height: calc(100% - (var(--#{$prefix}e-toolbar-height) + 10px));
}

.r-drawer-height {
  height: 100%;
}

.media-custom-h {
  height: 100% !important;
  top: 0 !important;
}

.r-bg {
  background-color: transparent;
}

@media (max-width: 1024px) {

  [data-kt-app-header-fixed="true"][data-kt-app-sidebar-fixed="true"][data-kt-app-sidebar-push-header="true"] .app-header.r-header,
  [data-kt-app-header-fixed="true"][data-kt-app-sidebar-sticky="on"][data-kt-app-sidebar-push-header="true"] .app-header.r-header {
    left: 0;
  }
}

@media (max-width: 992px) {
  .r-drawer-height {
    height: calc(100% - 140px) !important;
  }
}

@media (max-width: 1023px) {
  .article-d-none {
    display: none !important;
  }

  .template-app-wrapper {
    margin-left: 0 !important;
    margin-top: 0;
  }
}

@media (max-width: 1025px) {
  .h-r-preview {
    height: 100% !important;

    .article-w-220px {
      width: 270px;
    }
  }

  .r-drawer-height {
    height: calc(100% - 140px) !important;
  }

  .r-drawer-height {
    height: calc(100% - 156px);
  }

  .r-bg {
    background-color: white;
  }
}

@media (max-width: 1024px) {
  .media-custom-h {
    height: calc(100% - 150px) !important;
    top: 75px !important;
  }

  .article-pos {
    position: absolute !important;
    right: 0 !important;
  }

  .article-header-btn {
    display: none;
  }

  .article-footer-style {
    display: block;
  }

  app-article-editor {
    .drawer-wrapper {
      position: absolute;
      top: 0 !important;
      height: 100%;

      &.closed {
        display: none;
      }
    }
  }

  .a-editor-gap {
    gap: 0 !important;
  }

  .article-drawer-height {
    height: calc(100% - 120px);
  }

  .article-w-220px {
    width: 270px;
  }

  .article-header-rounded {
    border-radius: 0rem 0rem 0.625rem 0.625rem !important;
  }

  // .article-header-rounded-bottom{
  //   border-radius: 0rem 0rem 1rem 1rem !important;
  // }

  .article-px-5 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  app-article-content {
    &.app-content {
      padding-top: 0px !important;
    }
  }

  .article-d-height {
    height: calc(100% - 60px);
  }
}

@media (max-width: 768px) {
  .smart-head-pos {
    position: absolute;
    top: -21px;
  }

  .editor-container-label.inner {
    gap: 5px;
    top: -21px;
    left: 0;
    transform: none;
    padding: 0px 7px;
    width: -moz-fit-content;
    width: fit-content;
    white-space: nowrap;
    height: 20px;
    font-size: 10px;

    .delete-btn {
      span {
        svg {
          height: 10px;
          width: 10px;
        }
      }
    }

    .move-btn {
      span {
        svg {
          height: 10px !important;
          width: 10px !important;
        }
      }
    }

    .h-r-preview {
      height: 100% !important;

      .article-w-220px {
        width: 100%;
      }
    }
  }

  .article-d-height {
    height: calc(100% - 10px);
  }

  .media-custom-h {
    height: auto !important;
    top: auto !important;
    bottom: 0 !important;
    max-height: 100%;
  }

  .article-table-container {
    display: none;
  }

  .mobile-media-height {
    max-height: 400px !important;
    overflow-y: auto;
  }

  .article-rounded-4 {
    border-radius: 0 0 1rem 1rem !important;
  }

  .article-overlay {
    z-index: 0;
    background-color: #000000;
    position: fixed;
    opacity: 0.4;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .article-rounded-top-4 {
    border-radius: 0;
  }

  .article-h-auto {
    height: auto;
  }

  .btn-active-dark-green:hover {
    background-color: darkgreen !important;
  }

  app-d-selector {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  app-d-add-on-selector {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .article-w-220px {
    width: 100%;
  }

  .h-r-preview {
    height: 100% !important;

    .article-w-220px {
      width: 100% !important;
    }
  }

  .article-drawer-padding {
    padding-bottom: 40px;
  }

  .left-label {
    left: 73% !important;
  }

  .jg-expand-add-btn {
    position: absolute;
    top: -28px;
    right: -6px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #ecf1ff;
    border-radius: 5px 5px 0 0;
  }

  .jg-expand-add-btn {
    font-size: 10px;

    svg {
      height: 10px;
      width: 10px;
    }
  }

  .ql-snow .ql-picker-label {
    font-size: 10px;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    height: 20px;
    width: 20px;
  }

  .jg-expand-del-btn {
    top: 0px;
  }

  .ql-formats button[value="arrow"]::before {
    font-size: 20px !important;
  }

  .article-drawer-padding {
    padding-bottom: 0px;
  }

  // .article-name-wrapper {
  //   display: none;
  // }

  .drawer-click-md-disable {
    pointer-events: none;
  }

  .template-content.app-content {
    padding-top: 0;
  }

  .review-artlicle-custom-style {
    .template-content.app-content {
      padding-top: 10px;
    }
  }
}

@media (max-width: 576px) {
  .article-justify-center {
    justify-content: center;
  }

  .smart-head-pos {
    top: -19px;
    left: 50%;
  }
}

@keyframes scrolledAnimation {
  0% {
    background-color: transparent;
  }

  25% {
    background-color: rgba(151, 223, 118, 0.25);
  }

  40% {
    background-color: rgba(151, 223, 118, 50%);
  }

  50% {
    background-color: rgb(151, 223, 118);
  }

  60% {
    background-color: rgba(151, 223, 118, 50%);
  }

  75% {
    background-color: rgba(151, 223, 118, 25%);
  }

  100% {
    background-color: transparent;
  }
}

.scrolled-animation {
  animation-name: scrolledAnimation;
  animation-duration: 1000ms;
  animation-delay: 300ms;
  animation-iteration-count: 1;
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }
}

@media (min-width: 1024px) {
  .fixed-bottom-editor-bar {
    margin-left: calc(70px + var(--#{$prefix}app-sidebar-gap-start, 0px) + var(--#{$prefix}app-sidebar-gap-end, 0px));
    width: calc(100% - 70px) !important;
  }
}

@media (min-width: 1025px) {
  .d-ipad-pro-flex {
    display: flex !important;
  }

  .w-ipad-pro-300px {
    width: 300px !important;
  }

  .w-ipad-pro-400px {
    width: 400px !important;
  }

  .w-ipad-pro-500px {
    width: 500px !important;
  }

  .position-ipad-pro-static {
    position: static !important;
  }

  .d-ipad-pro-flex {
    display: flex !important;
  }
}

@media (max-height: 1024px) {
  .html-preview-container {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
}

@media (max-height: 768px) {
  .html-preview-container {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}

@media (max-width: 1024px) {
  .html-preview-container {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .editor-container-wrapper {

    &.mobile,
    &.tablet,
    &.desktop,
    &.fatafat {
      width: 100% !important;
    }
  }

  .mobile,
  .tablet,
  .desktop {

    .mobile-preview,
    .tablet-preview,
    .laptop-preview,
    .fatafat-preview {
      display: none !important;
    }
  }
}

// assignee  flow style

.assignee-flow {
  .ng-select-container {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    border-radius: 0.625rem !important;
    border: 1px solid #e0e6f5 !important;
    cursor: pointer;

    .ng-value-container {
      .ng-placeholder {
        font-size: 1.075rem !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .assignee-flow {
    .ng-value-container {
      .ng-placeholder {
        font-size: 0.7rem !important;
      }
    }
  }
}

.fs-8px {
  font-size: 8px;
}

.custom-view-button:hover {
  span {
    color: white;
  }
}

.collect-article-list {
  .p-datatable-wrapper {
    height: 400px !important;
    overflow-y: auto !important;
  }
}

.mobile.preview-padding {
  padding: 0 32px 0 32px;

  @media (max-height: 576px) {
    &.mobile {
      width: 280px;
    }
  }
}

.fatafat.preview-padding {
  padding: 60px 32px 60px 32px !important;

  @media (max-height: 576px) {
    &.fatafat {
      width: 280px;
    }
  }
}

.tablet.preview-padding {
  padding: 0 45px 0 45px;

  @media (max-height: 576px) {
    padding: 38px 125px 38px 125px !important;
  }
}

.desktop.preview-padding {
  padding: 58px 150px 80px 150px !important;

  @media (max-height: 576px) {
    padding: 58px 240px 80px 250px !important;
  }
}

.laptop-outlet-image {
  @media (max-height: 576px) {
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }
}

.tablet-outlet-image {
  @media (max-height: 576px) {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
}

@media (max-width: 1024px) {
  .mobile.preview-padding {
    padding: 0 10px 0 10px;
  }

  .fatafat.preview-padding {
    padding: 0 10px 0 10px;
  }

  .tablet.preview-padding {
    padding: 0 10px 0 10px;
  }

  .desktop.preview-padding {
    padding: 0 10px 0 10px !important;
  }
}

.whitespace-prewrap {
  white-space: pre-wrap;
}

.comment-first-column-width {
  @media (max-width: 1280px) {
    width: 300px;
  }
}

.xs-checkbox {
  .form-check.form-check-sm .form-check-input {
    height: 1.3rem;
    width: 1.3rem;
  }
}

.splide__track {
  overflow-y: visible !important;
}

// @media (max-width: 768px) {
//     .daterangepicker.show-ranges {
//       // display: flex !important;
//       overflow-x: auto !important;
//       max-width: 100%;
//       padding-bottom: 10px;
//       // position: relative;
//     }
//     .drp-buttons{
//       position: absolute !important;
//       bottom: 0 !important;
//     }
//   }
@media (max-width: 768px) {
  .topic-list {
    app-table-layout-one {
      .ng-placeholder {
        font-size: 0.75rem !important;
      }
    }
  }

  // .media-list-custom-style {
  //   .ng-select .ng-select-container {
  //     font-size: 0.75rem !important;
  //   }
  // }
}

.smart-headline-style,
.seo-setting-style,
.summarization-style {
  border: 1px solid #ecf1ff;
  border-radius: 5px;

  .accordion-border-top {
    border-top: 2px solid #ecf1ff;
  }

  .accordion-body {
    padding: 0 !important;
    border: 0 !important;
  }

  .accordion-item {
    padding: 8px 0 !important;
  }
}

.article-history-custom-style {
  .p-datatable-wrapper {
    height: 400px;
    overflow-y: auto;
  }
}

.article-history-paginator {
  z-index: 1062 !important;
}

.art-cmt-overlay {
  position: relative;
  // margin-bottom: 50px;

  .overlay-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d0dcff63;
    transition: all 0.3s ease;
    opacity: 0;
    border: 2px solid #aeb3db;
    // z-index: 999;
  }

  &.overlay-show,
  &.overlay-block,
  &:hover {
    .overlay-layer {
      transition: all 0.3s ease;
      opacity: 1;
    }
  }

  &.overlay-block {
    cursor: wait;
  }
}

.fatafat-btn {
  bottom: 60px;
  left: 26px;
  width: 85.2%;
  background-color: #f44236;
}

.bg-bcecfb-btn {
  .accordion-button {
    background-color: #bcecfb;
    border-bottom-left-radius: var(--#{$prefix}accordion-inner-border-radius);
    border-bottom-right-radius: var(--#{$prefix}accordion-inner-border-radius);
  }

  .accordion-header {
    padding: 8px;
  }
}

.reviewer-article-style {
  .h-e-preview {
    height: calc(100% - (var(--jg-e-toolbar-height) + 10px)) !important;

    @media (min-width: 1080px) {
      padding-bottom: 60px;
    }
  }
}

.version-control-iframe {
  height: 100%;
  padding: 31px;
  width: 530px;
  max-width: 100%;
  margin: auto;
  position: relative;
  z-index: 5;

  @media (max-height: 576px) {
    width: 300px;
    padding: 20px;
  }
}

.review-article-custom-accordion {
  .accordion-button {
    padding: 0.5rem 0.75rem;
  }

  .accordion-body {
    padding: 0.75rem;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  .accordion-button {
    background-color: var(--#{$prefix}light-purple);
    font-size: 1.15rem;
    font-weight: 500;
    border-top-left-radius: var(--jg-border-radius-lg);
    border-top-right-radius: var(--jg-border-radius-lg);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.line-clamp>* {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-5>* {
  -webkit-line-clamp: 5;
}

.line-10>* {
  -webkit-line-clamp: 10;
}

.seo-accordion-button {
  .common-custom-accordion {
    .accordion-button {
      background-color: white;
      font-size: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &::after {
        background-size: cover;
      }
    }
  }

  .accordion-body {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0.25rem;
  }
}

.transparent-accordion {
  .accordion-item {
    background-color: transparent;
  }

  .accordion-body {
    background-color: white;
  }
}

.accordion-inner-scroll {
  .accordion-body {
    margin-top: 10px;
    margin-bottom: 10px;
    max-height: 410px;
    overflow-y: auto;
    background-color: transparent;
  }
}

.topic-list {
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-right: 60px !important;
  }
}

.mb-ipad-0 {
  @media (min-width: 769px) {
    margin-bottom: 0 !important;
  }
}

.pb-fixed-bar {
  @media (max-width: 767px) {
    padding-bottom: 63px;
  }
}

.scroll-margin-100 {
  scroll-margin: 100px;
}

.fn-highlight p {
  padding: 10px 0px;
}

.fn-highlight p:not(:last-of-type) {
  border-bottom: 1px solid #f0efef;
}

.fn-highlight {
  border: 1px solid #f0efef;
  margin: 25px 0 30px;
  padding: 25px;
  background: #fff;
  overflow: hidden;
  box-shadow: -2px 2px 20px 0 #efefef;
}

.fn-highlight h3:before {
  content: "";
  display: inline-flex;
  width: 12px;
  height: 12px;
  border: 2px solid red;
  border-radius: 9px;
  margin-right: 8px;
}

.fn-highlight h3 {
  margin-bottom: 15px;
  // line-height: 1.6;
  // font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  // padding-left: 20px;
}

.version-control-img {
  width: 530px;

  @media (max-height: 576px) {
    width: 300px;
  }
}

.border-radius-feed {
  border-radius: 0 1rem 1rem 1rem;
}

.plan-renewal-height {
  max-height: 500px;

  @media (max-height: 576px) {
    max-height: 300px;
  }
}

.article-live-blog-list {
  display: block;
  clear: both;
  padding: 25px 25px 0 25px;
  position: relative;
  border-bottom: 1px solid #f0efef;
}

.article-live-blog-list:after {
  content: " ";
  position: absolute;
  top: 33px;
  left: -5px;
  width: 9px;
  height: 9px;
  background-color: #ec1d25;
  border-radius: 50%;
  outline: 5px solid rgba(236, 29, 37, 0.1);
}

.mobile,
.tablet {
  .article-live-blog-time {
    background-repeat: no-repeat;
    background-position: 3px;
    background-size: 12px 12px;
    font-size: 11px;
    line-height: 16px;
    color: #010101;
    display: block;
    // position: absolute;
    // left: -76px;
    // top: 30px;
    // text-align: right;
  }

  .timeLineTime {
    font-size: 10px;
    line-height: 19px;
    color: black;
    // text-align: right;
    // background-color: #000;
    border-radius: 15px;
    // padding: 0 10px;
  }

  .article-live-blog {
    display: block;
    // width: calc(100% - 115px);
    width: 100%;
    box-shadow: 0 0 10px #e8e8e8;
    border-radius: 15px;
    // margin: 0 0 16px 100px;
  }
}

.timeLineTime {
  font-size: 12px;
  line-height: 19px;
  color: black;
  border-radius: 15px;
}

.jg-fs-10px {
  font-size: 12px;
}

.desktop {
  .article-live-blog-time {
    background-repeat: no-repeat;
    background-position: 3px;
    background-size: 12px 12px;
    font-size: 11px;
    line-height: 16px;
    color: #010101;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -100px;
    top: 30px;

    // text-align: right;
    div {
      width: 90px;
    }
  }

  .timeLineTime {
    font-size: 10px;
    line-height: 19px;
    color: black;
    text-align: right;
    // background-color: #000;
    border-radius: 15px;
    padding: 0 10px;
  }

  .article-live-blog {
    display: block;
    width: calc(100% - 115px);
    // width: 100%;
    box-shadow: 0 0 10px #e8e8e8;
    border-radius: 15px;
    margin: 0 0 16px 100px;
  }
}

.article-live-blog-time small {
  color: #da251d;
  font-size: 10px;
  display: block;
}

.p-event-none {
  pointer-events: none;
  cursor: none;
}

.liveBlog-txt {
  color: #da251d;
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 0.25rem;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  height: 25px;
  width: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 10px;
}

.liveBlink {
  background-color: #da251d;
  height: 11px;
  width: 11px;
  display: inline-block;
  line-height: 0px;
  left: 0;
  bottom: 0;
}

.liveBlink:before {
  content: "";
  height: 4px;
  width: 4px;
  left: 3.75px;
  top: 4px;
  background-color: transparent;
  box-shadow: 0 0 0 4px #da251d;
  animation: active 2s linear infinite;
}

.liveBlink,
.liveBlink:before {
  border-radius: 50%;
  position: absolute;
}

@keyframes active {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }

  70% {
    transform: scale(2.5);
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

ngx-codemirror .CodeMirror-wrap {
  border: 1px solid grey;
  border-radius: 10px;
  height: 450px;
  margin: 18px;
}

.ai-ml-over:hover {
  background-color: var(--jg-light-purple);
}

.disable-live-blog-add {
  cursor: none;
  pointer-events: none;
  opacity: 0.5;
}

.acc-body-p-0 {
  .accordion-body {
    padding: 0;
  }
}

.h-google-trends {
  height: calc(100vh - 97px);
}

.text-truncate-custom {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}

.trends-item-border {
  border: 1px solid #e0e6f5;
  border-bottom: 0;

  &:last-child {
    border-bottom: 1px solid #e0e6f5;
  }
}

.FIE_topbar *,
.FIE_tools-bar *,
.FIE_tabs * {
  color: #605f5f;
}

// .gcXnsi {
//   background-color: var(--jg-purple-btn) !important;
// }

// .gcXnsi[aria-selected="true"] {
//   background-color: var(--jg-dark) !important;
// }

.seo-feild-tags {
  .app-tags-container {
    border: 0 !important;
  }
}

.textarea-border-0 {
  textarea {
    border: 0;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.inner-articles-pl {
  padding-left: 40px;

  @media (max-width: 576px) {
    padding-left: 20px;
  }
}

.google-trends-count {
  min-width: 50px;
  font-size: 32px;

  @media (max-width: 576px) {
    min-width: 30px;
    font-size: 25px;
  }
}

.g-trends-img {
  @media (max-width: 576px) {
    width: 40px;
    height: 40px;
  }
}

.g-trends-acc {
  .accordion-button {
    gap: 10px;
  }
}

.g-trends-inner-container-pl {
  padding-left: 40px;

  @media (max-width: 576px) {
    padding-left: 10px;
  }
}

.g-trends-article-gap {
  gap: 24px;

  @media (max-width: 576px) {
    gap: 10px;
  }
}

.is-breaking-container {
  border: 1px solid var(--jg-gray-300);
}

.pt-breaking-label {
  padding-top: 8px;
}

.r-e-d-input {
  margin: 0.5rem auto 0 !important;
  width: calc(100% - 1rem) !important;
}

.seo-feild-input {
  input {
    border: 0 !important;
  }
}

.nav-accordion {

  .accordion-item {
    background-color: transparent;
    border: 0;
  }

  .accordion-button {
    border-radius: 0 !important;
    background-color: transparent;
    color: #9D9DA6;
    transition: color 0.2s ease;
    padding: 0 !important;

    &:hover {
      color: var(--jg-primary-inverse);
    }
  }
}

.left-side-value {
  .ng-value-container {
    justify-content: flex-end;
  }
}

.line-height-normal {
  line-height: 1.5 !important;
}

.visibility-hidden {
  visibility: hidden;
}

// 23/11/2023-shivam_suthar

.dropdown-toggle.delete-btn.px-1.lh-1.no-arrow.addbtn-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2D2D4A;
}

.article-delete-btn.svg-icon.svg-icon-8.addbtnicon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -1px 2px 0 0;
}

.article-delete-btn.svg-icon.svg-icon-8.addbtnicon svg {
  height: 8px !important;
  width: 8px !important;
}

.modal-inner-form {
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    min-height: 516px;
    justify-content: end;
  }

  .dropzone {
    @media (min-width: 1024px) {
      min-height: 416px;
    }
  }

  .file-uploaded-image {
    @media (min-width: 1024px) {
      min-height: 416px;
    }
  }
}

.add-image-modal-body {
  .media-gridview-h {
    height: 100%;
    max-height: 400px;
    min-height: 400px;
  }
}

// 28/11/2023-shivam_suthar

.add-content-icon {
  background-color: #BFC4F0;
}

.search-inner-wrpper {
  .search-wrapper {
    @media (min-width: 1024px) {
      padding-left: 280px;
    }
  }
}

.all-folder-wrp {
  @media (min-width: 1024px) {
    left: 12px;
    top: 3px;
    display: block;
    position: absolute;
    max-width: 260px;
  }

  .ng-select-container {
    padding: .5rem 1rem !important;
    font-size: 1.075rem !important;
  }
}

.appoinment-chatbox {
  .card-main {
    min-height: 200px;

    @media (max-width: 1440px) {
      max-height: 220px;
    }


    .chat-item {
      // max-width: 360px;

      .chat-item-chatgrp {
        .chat-item-chat {
          width: fit-content;
        }
      }

      .chat-item-downloadgrp {
        .chat-item-download {
          width: fit-content;
        }
      }

      // &:nth-of-type(even) {
      //   margin: 0 0 0 460px;

      //   @media (max-width: 1440px) {
      //     margin: 0 0 0 400px;
      //   }

      //   @media (max-width: 1280px) {
      //     margin: 0 0 0 320px;
      //   }
      // }
    }
  }
}

.complete-card {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 768px;
  margin: auto;
  position: absolute;
  text-align: center;
}

// .register-sidebar {
//   background-color: #ffeff9;
// }

.stepper.stepper-pills.stepper-column .stepper-line {
  border-color: #d0d3df !important;
}

.stepper-icon {
  border-color: #d0d3df !important;
}

// dashboard card design

.dashboard-card {
  min-height: 320px;

  .table-responsive {
    overflow-y: auto;
    max-height: 240px;
    min-height: 240px;
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;

    .table {

      thead {

        tr {
          th {
            font-size: 14px;
            color: black;
            padding: 0 0 8px !important;
          }
        }
      }

      tbody {
        tr {
          &:not(:last-of-type) {
            border-bottom: 1px dashed rgba(0, 0, 0, 0.2) !important;
          }

          td {
            span {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }

  .no-result-found {
    min-height: 100%;
    flex-grow: 1;
  }

}

// .designbox{
//   .designbox-text{
//     border-color: $pink !important;
//   }
// }
.custom-checkbox{
  border: 1px solid black !important; 
}
.w-250px{
  width: 250px !important;
}
.min-w-55ch{
  min-width: 55ch !important;
  @media (max-width: 767px) {
    min-width: auto !important;
  }
}
.max-w-70ch{
  max-width: 55ch !important;
}
.whitespace-break{
white-space: break-spaces !important;
}

a[target="_blank"]{
  color: $external-link;
  &:hover {
    text-decoration: underline !important;
  }
}

.btn-bg-light-purple {
  background-color: var(--#{$prefix}light-purple);

  &.purple-btn {
    background-color: var(--jg-purple-btn);
  }
}

.register-sidebar{
  background-color: $secondary-extralight;
}