.btn {
    .svg-icon {
        transition: fill 0.15s ease-in-out;
    }
}

.purple-btn {
    background-color: var(--#{$prefix}purple-btn) !important
}

.btn.purple-active-btn {
    background-color: var(--#{$prefix}purple-btn) !important;

    .svg-icon {
        color: var(--#{$prefix}white) !important;
    }

    &:focus:not(.btn-active),
    &:hover:not(.btn-active) {
        background-color: var(--#{$prefix}purple-btn) !important;

        .svg-icon {
            color: var(--#{$prefix}white) !important;
        }
    }
}


// .btn.btn-pink {
//     --#{$prefix}pink: #f25769 ;
//     --#{$prefix}pink-active: #ce4a5a;
//     --#{$prefix}pink-light: #f8939f;
//     --#{$prefix}pink-inverse: #ffffff ;
//     --#{$prefix}pink-extralight:#fbf3f4;

//     color: var(--#{$prefix}pink-inverse) !important;

//     .svg-icon {
//         color: var(--#{$prefix}pink-inverse) !important;
//     }

//     &:focus:not(.btn-active),
//     &:hover:not(.btn-active) {
//         background-color: var(--#{$prefix}pink-active) !important;
//         color: var(--#{$prefix}pink-inverse) !important;

//         .svg-icon {
//             color: var(--#{$prefix}pink-inverse) !important;
//         }
//     }
// }

.jg-danger-light {
    background-color:var(--#{$prefix}delet-btn-color) !important;
}