//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// Theme colors
// Primary
// $primary:       									#009ef7;
// $primary-active:    								#0095e8;
// $primary-light:    								    #f1faff;
// $primary-light-dark:    							#212e48;
// $primary-inverse:  									$white;

$primary: #76182f;;
$primary-active: #b57376;
$primary-light: #ecb8bb30;
$primary-light-dark: #212e48;
$primary-inverse: white;
$secondary-light:#b57376;
$secondary-extralight:#ecb8bb50;

// pink

$pink: 				    		#f25769 !default;
$pink-active: 		    		#ce4a5a !default;
$pink-light: 				    #f8939f !default;
$pink-inverse: 		    		#ffffff !default;
$pink-extralight:                 #fbf3f4 !default;

$external-link: #009ef7 !default;

// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;

