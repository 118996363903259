.jg-sidebar-menu {
    background-color: var(--#{$prefix}primary);

    // iner classes
    .menu>.menu-item {
        overflow-x: hidden;
    }

    .menu-item .menu-link {
        padding: 0.75rem 0.85rem;
    }
}

.menu_heading {
    color:var(--#{$prefix}sidebarmenu-heading);
    // color: $pink;
    font-weight: 500;
}

.menu-title {
    color:var(--#{$prefix}menu-title)
}

// .app-sidebar .menu .menu-item .menu-link .menu-icon .svg-icon{
//     color: var(--#{$prefix}sidebar-svg-icon);
// }

.sidebar_icon {
    color: var(--#{$prefix}sidebar-svg-icon) !important;
}

.menu-link:hover .sidebar_icon {
    color:var(--#{$prefix}white-color) !important;
}

.menu-container {
    padding-right: 1rem;
    padding-left: 1rem;
}

.side-menu-heading-item {
    transition: all 0.3s ease;
}

.template-editor-sidebar {
    @media (min-width: 992px) {

        .menu-title,
        .menu-item.side-menu-heading-item {
            display: none !important;
        }

        .menu-container {
            padding-right: 0.7rem;
            padding-left: 0.7rem;
        }

        .menu-link {
            padding: 0.6rem 0.15rem !important;
        }

        .icon-logo {
            display: flex;
        }

        .full-logo {
            display: none;
        }
    }

    @media (max-width: 991.98px) {
        .icon-logo {
            display: none;
        }

        .full-logo {
            display: flex;
        }
    }
}

[data-kt-app-sidebar-minimize="on"] {
    .side-menu-heading-item {
        @media (min-width: 992px) {
            height: 0;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }
    }

    .app-sidebar-menu {
        .hover-scroll-overlay-y{
            scrollbar-width: 0 !important;
    
            &::-webkit-scrollbar {            
                width:0 !important;
                height:0 !important;
            }
        }
    }

 

    .menu-container {
        padding-right: 0.65rem;
        padding-left: 0.65rem;

        .menu-link {
            padding: 0.6rem 0.35rem;

            .menu-icon {
                width: 1.8rem;
            }
        }

        .menu-title {
            @media (min-width: 992px) {
                height: 0;
                margin: 0;
                padding: 0;
                overflow: hidden;
            }
        }

        .menu-item:not(.side-menu-heading-item) {
            margin-bottom: 10px;
        }
    }
}

.custom-scroll{
    .hover-scroll-overlay-y{
        scrollbar-width: 0 !important;

        &::-webkit-scrollbar {            
            width:0 !important;
            height:0 !important;
        }
    }
}